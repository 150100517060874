import { createAsyncThunk } from '@reduxjs/toolkit';
import businessProfileAPI from 'api/customer-profile/business-profile-api';

// Thunk Actions
const getBusinessProfile = createAsyncThunk(`businessProfile/getData`, async ({ company_id }) => {
    const response = await businessProfileAPI.getBusinessProfile({company_id});
    return response;
});

export {
    getBusinessProfile
};
