import axiosClient from "api/axios-client";

export const getCreditData = async ({ company_id }) => {
  console.log("api - company_id", company_id);
  const response = await axiosClient.get("/ifincredit/customerProfile/getCreditProfile",{ params: { company_id } });
  console.log("response", response);
  return response;
};

export const postCreditData = async (data) => {
  const response = await axiosClient.post(
    "/ifincredit/customerProfile/CreditProfile",
    { data }
  );
  return response;
};
