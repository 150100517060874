import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetProfileReport, GetUserActivites } from "api/dashboard/management-report-api";

export const ProfileReport = createAsyncThunk("/dashbaord/getProfileReport", async () => {
    const response = await GetProfileReport();
    return response;
})

export const UserActivites = createAsyncThunk("/dashboard/UserActivites", async (dateRange) => {
    //console.log(dateRange)
    var startDate = new Date(dateRange[0].startDate)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)

    var endDate = new Date(dateRange[0].endDate)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setMinutes(59)

    //console.log(endDate)

    const response = await GetUserActivites({startDate, endDate});
    return response;
})