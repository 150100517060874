import axiosClient from 'api/axios-client';

const getCompanyGroup = async ({tarco_id}) => {
    var url = `/ifincredit/company_group/`;
    if (tarco_id) url += `?tarco_id=${tarco_id}`
    var response = await axiosClient.get(url);
    return response;
};

const userApi = {
    getCompanyGroup
};

export default userApi;
