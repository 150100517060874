import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUS } from "../redux-constants";

import actions from './user-info-actions';

const initialState = {
  data: {},
  loading: LOADING_STATUS.IDLE,
};

const getUserInfoPendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const getUserInfoFulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: { ...action.payload },
  };
};

const getUserInfoRejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: initialState,
  reducers: {
    updateUserInfo: (state, { payload }) => {
      state.data = payload;
    },
    resetInfo: (state, _) => {
      state.data = {};
    }
  },
  extraReducers: {
    [actions.getUserInfo.pending]: getUserInfoPendingReducer,
    [actions.getUserInfo.fulfilled]: getUserInfoFulfilledReducer,
    [actions.getUserInfo.rejected]: getUserInfoRejectedReducer,
  },
});

export const userInfoReducers = userInfoSlice.reducer;
export const userInfoActions = {
  ...actions,
  ...userInfoSlice.actions,
};
