import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from './redux-constants';

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from '../api/axios-client';

// Thunk Actions
const getLoginInfo = createAsyncThunk(`login/getInfo`, async (url_requesting) => {
  const response = await axiosClient.get(`/ifincredit`, { params:  { url_requesting: url_requesting } });
  return response;
});

// Reducers
const initialState = {
  data: {},
  loading: LOADING_STATUS.IDLE,
};

const getLoginInfoPendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const getLoginInfoFulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: { ...action.payload },
  };
};

const getLoginInfoRejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};


const loginInfoSlice = createSlice({
  name: 'login',
  initialState,
  extraReducers: {
    [getLoginInfo.pending]: getLoginInfoPendingReducer,
    [getLoginInfo.fulfilled]: getLoginInfoFulfilledReducer,
    [getLoginInfo.rejected]: getLoginInfoRejectedReducer,
  },
});


// Selectors
const loginInfo = () => (state) => state.loginInfo;


export const loginInfoReducers = loginInfoSlice.reducer;
export const loginInfoActions = {
    getLoginInfo,
    ...loginInfoSlice.actions,
};
export const loginInfoSelector = loginInfo;