import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetCustomerPortfolio } from 'api/dashboard/dashboard-api'

const GetPortfolio = createAsyncThunk("/CustomerProfolio", async () => {
    //console.log(data)
    const response = await GetCustomerPortfolio(); 
    console.log(response);
    return response
})

export {
    GetPortfolio
}

