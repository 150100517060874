import { createAsyncThunk } from '@reduxjs/toolkit';
import userApi from 'api/group-details-api';

// Thunk Actions
const getGroupDetails = createAsyncThunk(`groupDetails/getGroupDetails`, async ({company_id}) => {
    var response = await userApi.getGroupDetails({company_id});
    return response;
})

export {
    getGroupDetails
};
