import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginInfoActions, loginInfoSelector } from './slices/login-info-slices';
import { LOADING_STATUS } from 'slices/redux-constants';
import Loading from 'sharedComponents/LoadingComponents/Loading';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme';

export const UserInfoPage = lazy(() => import('./screens/user-info'));
export const CustomerOverviewPage = lazy(() => import('./screens/customer-overview'));
export const CustomerProfile = lazy(() => import('./screens/customer-profile'));
export const TradeTermChange = lazy(() => import('./screens/trade-term-change'));
export const NewCustomer = lazy(() => import("./screens/new-customer"));
export const Template = lazy(() => import('./sharedComponents/TemplateComponents/SideBarComponent'));
export const CustomerProfolio = lazy(() => import('./screens/DashBoard/CustomerPortfolio'))
export const TaskManager = lazy(() => import('./screens/DashBoard/TaskManager'))
export const Error = lazy(() => import('./screens/error/Error'));
export const ManagementReport = lazy(() => import('./screens/DashBoard/ManagementReport'))
export const CreditAnalysis = lazy(() => import('./screens/credit-analysis'))
export const Dashboard = lazy(() => import('./screens/DashBoard/Dashboard'))

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const loginInfoState = useSelector(loginInfoSelector());

  useEffect(() => {
    dispatch(loginInfoActions.getLoginInfo(window.location.href));
  }, [children]);

  if (loginInfoState.loading === LOADING_STATUS.FULFILLED) {
    return (children);
  }

  else {
    return (<Loading />)
  }
};



function App() {

  const [no_params_url, setNoParamsUrl] = useState("")
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<></>}>
            <Template no_params_url={no_params_url}>
              <Routes>
                <Route path='/' element={<Navigate to="/dashboard" />} />
                <Route path="/customer-portfolio" element={<ProtectedRoute><CustomerProfolio setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                {/* <Route path="/customer-overview/new_compa/trade-term-change/new_request" element={<ProtectedRoute><NewCustomer /></ProtectedRoute>} /> */}
                <Route path="/customer-overview/new_company/trade-term-change/new_request" element={<ProtectedRoute><NewCustomer setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                <Route path="/customer-overview/:company_id/credit-analysis/" element={<ProtectedRoute><CreditAnalysis setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                <Route path="/customer-overview/:company_id/trade-term-change/:request_id" element={<ProtectedRoute><TradeTermChange setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                <Route path="/customer-overview/:company_id/trade-term-change/" element={<ProtectedRoute><TradeTermChange /></ProtectedRoute>} />
                <Route path="/customer-overview/:company_id/customer-profile" element={<ProtectedRoute> <CustomerProfile setNoParamsUrl={setNoParamsUrl}/> </ProtectedRoute>} />
                <Route path="/customer-overview/:company_id" element={<ProtectedRoute> <CustomerOverviewPage setNoParamsUrl={setNoParamsUrl}/> </ProtectedRoute>} />
                <Route path="/task-manager" element={<ProtectedRoute><TaskManager setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                <Route path="/customer-profile" element={<ProtectedRoute> <CustomerProfile setNoParamsUrl={setNoParamsUrl}/> </ProtectedRoute>} />
                <Route path="/management-report" element={<ProtectedRoute><ManagementReport setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard setNoParamsUrl={setNoParamsUrl}/></ProtectedRoute>} />
                <Route path="/error_page" element={<Error />} />
                <Route path="*" element={<div>No such page</div>} />
              </Routes>
            </Template>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

App.propTypes = {};

export default App;
