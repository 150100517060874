import { createAsyncThunk } from "@reduxjs/toolkit";
import { postCreditData, getCreditData } from 'api/customer-profile/credit-profile-api'

const PostCredit = createAsyncThunk('CreditProfile/PostCredit', async (data) => {
    const response = await postCreditData(data);
    return response;
})

const GetCredit = createAsyncThunk('CreditProfile/GetCredit', async({company_id})=>{
    const response = await getCreditData({company_id});
    return response;
})

export { PostCredit, GetCredit }