import { createAsyncThunk } from "@reduxjs/toolkit";
import tradeProfileAPI from "api/customer-profile/trade-profile-api";

// Thunk Actions
const getTradeProfile = createAsyncThunk(`tradeProfile/getData`, async (data) => {
    console.log("get trade profile", data);
    const response = await tradeProfileAPI.getTradeProfile(data);
    console.log("get response", response);
    return response;
  });

export { getTradeProfile };
