import { configureStore } from '@reduxjs/toolkit';
import { userInfoReducers } from './slices/user-info/user-info-reducers';
import { loginInfoReducers } from './slices/login-info-slices';
import { businessProfileReducers } from './slices/customer-profile/business-profile/business-profile-reducers';
import { companyGroupReducers } from './slices/customer-profile/company-group/company-group-reducers';
import { customerOverviewReducer } from './slices/customer-overview/customer-overview-reducers'
import { creditSliceReducers } from './slices/customer-profile/credit-profile/credit-profile-reducers'
import { groupDetailsReducers } from './slices/group-at-a-glance/group-details/group-details-reducers';
import { customerProfolioReducers } from './slices/dash-board/customer_portfolio/customer-portfolio-reducers'
import { tradeProfileReducers } from 'slices/customer-profile/trade-profile/trade-profile-reducers';
import { taskManagerReducers } from 'slices/dash-board/task_manager/task-manager-reducers';
import { ManagementReportReducers } from 'slices/dash-board/management_report/management_report_reducers';
const store = configureStore({
  reducer: {
    userInfo: userInfoReducers,
    loginInfo: loginInfoReducers,
    businessProfile: businessProfileReducers,
    tradeProfile: tradeProfileReducers,
    companyGroup: companyGroupReducers,
    customerOverview: customerOverviewReducer,
    creditProfile: creditSliceReducers,
    groupDetails: groupDetailsReducers,
    customerProfolio: customerProfolioReducers,
    taskManager: taskManagerReducers,
    managementReport: ManagementReportReducers
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
