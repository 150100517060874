const defaultConfig = {
    "PORT": 8080,
    "BASE_URL": "http://localhost:8080",
    "REACT_APP_API_URL": "http://localhost:4000",
    "APP_IFINGATE": "http://localhost:3001",
    "APP_IFINPULSE": "http://localhost:3002",
    "APP_IFINHEALTH": "http://localhost:3000"
}

export default defaultConfig;
