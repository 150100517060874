import { ProfileReport, UserActivites } from "./management_report_actions";
import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUS } from "slices/redux-constants";

const initialState = {
    profile: [],
    user_activities: [],
    Profileloading: LOADING_STATUS.IDLE,
    UserActivitiesLoading: LOADING_STATUS.IDLE
}

const ProfilePendingReducer = (state, action) => {
    return {
        ...state,
        profile: [],
        Profileloading: LOADING_STATUS.PENDING
    }
}

const FulfillProfileReducer = (state, action) => {
    return {
        ...state,
        profile: action.payload,
        Profileloading: LOADING_STATUS.FULFILLED
    }
}

const RejectedProfileReducer = (state, action) => {
    return {
        ...state,
        profile: [],
        Profileloading: LOADING_STATUS.REJECTED,

    }
}

const ActivitiesPendingReducers = (state, action) => {
    return {
        ...state,
        user_activities: [],
        UserActivitiesLoading: LOADING_STATUS.PENDING
    }
}

const ActivitesFulfillReducers = (state, action) => {
    return {
        ...state,
        user_activities: action.payload,
        UserActivitiesLoading: LOADING_STATUS.FULFILLED
    }
}

const ActivitiesRejectedReducers = (state, action) => {
    return {
        ...state,
        user_activities: [],
        UserActivitiesLoading: LOADING_STATUS.REJECTED
    }
}
const ManagementReportSlice = createSlice({
    name: 'ProfileReport',
    initialState,
    extraReducers: {
        [ProfileReport.pending]: ProfilePendingReducer,
        [ProfileReport.fulfilled]: FulfillProfileReducer,
        [ProfileReport.rejected]: RejectedProfileReducer,
        [UserActivites.pending]: ActivitiesPendingReducers,
        [UserActivites.fulfilled]: ActivitesFulfillReducers,
        [UserActivites.rejected]: ActivitiesRejectedReducers
    }
})

export const ManagementReportReducers = ManagementReportSlice.reducer
export const ManagementReportActions = {
    ProfileReport,
    UserActivites,
    ...ManagementReportSlice.actions
}