import axiosClient from "api/axios-client";

export const GetProfileReport = async () => {
    const response = await axiosClient.get("/ifincredit/dashboard/ProfileReport")
    return response;
}

export const GetUserActivites = async ({ startDate, endDate }) => {

    const response = await axiosClient.get('/ifincredit/dashboard/UserActivities', { params: { startDate, endDate } })
    return response
}