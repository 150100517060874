import { LOADING_STATUS } from "slices/redux-constants";
import { createSlice } from "@reduxjs/toolkit";
import { GetPortfolio } from './customer-portfolio-action'

const initialState = {
    data: {},
    loading: LOADING_STATUS.IDLE,
}

const PendingReducer = (state, action) => {
    return {
        ...state,
        data: {},
        loading: LOADING_STATUS.PENDING,
        error: false
    }
}

const FulfillReducer = (state, action) => {
    return {
        ...state,
        data: action.payload,
        loading: LOADING_STATUS.FULFILLED,
        error: false
    }
}

const RejectReducer = (state, action) => {
    return {
        ...state,
        data: {},
        loading: LOADING_STATUS.REJECTED,
        error: false
    }
}

const customerProfolioSlice = createSlice({
    name:"customerPortfolio",
    initialState,
    extraReducers:{
        [GetPortfolio.pending]: PendingReducer,
        [GetPortfolio.fulfilled]: FulfillReducer,
        [GetPortfolio.rejected]: RejectReducer
    }
})

export const customerProfolioReducers = customerProfolioSlice.reducer
export const customerProfolioActions = {
    GetPortfolio,
    ...customerProfolioSlice.actions
}

