import axiosClient from 'api/axios-client';

const getGroupDetails = async ({company_id}) => {
    var url = `/ifincredit/company_group/details/${company_id}`;
    var response = await axiosClient.get(url);
    return response;
}

const userApi = {
    getGroupDetails
};

export default userApi;
