import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUS } from "slices/redux-constants";
import { getCompanyOverviewData, GetCompany } from './customer-overview-action'

const initialState = {
  data: {},
  header: {},
  loading: LOADING_STATUS.IDLE
}

const pendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const fulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: { ...action.payload },
  };
};

const rejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};

const headerFulfillReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    header: { ...action.payload }
  }
}

const CustomerOverviewSlice = createSlice({
  name: 'Overview',
  initialState,
  extraReducers: {
    [getCompanyOverviewData.pending]: pendingReducer,
    [getCompanyOverviewData.fulfilled]: fulfilledReducer,
    [getCompanyOverviewData.rejected]: rejectedReducer,
    [GetCompany.pending]: pendingReducer,
    [GetCompany.fulfilled]: headerFulfillReducer,
    [GetCompany.rejected]: rejectedReducer
  }
})

export const customerOverviewReducer = CustomerOverviewSlice.reducer
export const customerOverviewActions = {
  getCompanyOverviewData,
  GetCompany,
  ...CustomerOverviewSlice.actions
}