import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from 'slices/redux-constants';
import { getBusinessProfile } from './business-profile-actions';

// Reducers
const initialState = {
  data: {},
  loading: LOADING_STATUS.IDLE,
};

const pendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const fulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: { ...action.payload },
  };
};

const rejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};


const businessProfileSlices = createSlice({
  name: 'businessProfile',
  initialState,
  extraReducers: {
    [getBusinessProfile.pending]: pendingReducer,
    [getBusinessProfile.fulfilled]: fulfilledReducer,
    [getBusinessProfile.rejected]: rejectedReducer,
  },
});

export const businessProfileReducers = businessProfileSlices.reducer;
export const businessProfileActions = {
    getBusinessProfile,
    ...businessProfileSlices.actions,
};