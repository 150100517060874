import { createAsyncThunk } from '@reduxjs/toolkit';
import userApi from 'api/company-group-api';

// Thunk Actions
const getCompanyGroup = createAsyncThunk(`companyGroup/getData`, async ({tarco_id}) => {
    const response = await userApi.getCompanyGroup({tarco_id});
    return response;
});

export {
    getCompanyGroup
};
