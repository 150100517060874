import axios from 'axios';

import { API_URL } from 'constants/common-constants';
import exportConfig from '../config/getConfig';

const axiosClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const redirection_code = {
  not_logged_in: exportConfig.APP_IFINGATE,
  not_authorised: exportConfig.BASE_URL
};

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    const errorMessage = error?.response?.data;
    console.log("🚀 ~ file: axios-client.js ~ line 25 ~ errorMessage", errorMessage)
    if (errorMessage) {
      var redirection = redirection_code[errorMessage.message];
      if (redirection) {
        window.location.href = `/error_page?error_code=${errorMessage.message}&redirection=${redirection}`;
      }
    }; 

    throw errorMessage || error;
  }
);

export default axiosClient;
