import axiosClient from 'api/axios-client';
import Cookies from 'js-cookie';

const getBusinessProfile = async ({ company_id }) => {
    console.log("api - company_id", company_id)
    const response = await axiosClient.get(`/ifincredit/customerProfile/getBusinessProfile`, { params: { company_id } });
    return response;
};

const setCustomerCode = async (body) => {
    const response = await axiosClient.post("/ifincredit/customerBackground/setCustomerCode", body);
    return response;
};

const businessProfileAPI = {
    getBusinessProfile,
    setCustomerCode
}

export default businessProfileAPI;
