import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData, fetchCompany } from 'api/customer-overview/customer-overview-api'

//Thunk Action

const getCompanyOverviewData = createAsyncThunk('CustomerOverview/FetchData', async (data) => {
    const company_id = data;
    const response = await fetchData(company_id)
    return response;
});

const GetCompany = createAsyncThunk('CustomerOverview/GetCustomerCompany', async (data) => {
    const company_id = data;
    const response = await fetchCompany(company_id)
    return response
});

export {
    getCompanyOverviewData,
    GetCompany
};