import { createSlice, current } from '@reduxjs/toolkit';
import { LOADING_STATUS } from 'slices/redux-constants';
import { getGroupDetails } from './group-details-actions';

// Reducers
const initialState = {
  data: {
    group_details: {}
  },
  loading: LOADING_STATUS.IDLE,
};

const pendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const fulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: {
      ...action.payload 
    },
  };
};

const rejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};

const groupDetailsSlices = createSlice({
  name: 'groupDetails',
  initialState,
  extraReducers: {
    [getGroupDetails.pending]: pendingReducer,
    [getGroupDetails.rejected]: rejectedReducer,
    [getGroupDetails.fulfilled]: fulfilledReducer
  },
});

export const groupDetailsReducers = groupDetailsSlices.reducer;
export const groupDetailsActions = {
    getGroupDetails,
    ...groupDetailsSlices.actions,
};