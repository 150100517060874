import { createSlice, current } from "@reduxjs/toolkit";
import { PostCredit, GetCredit } from "./credit-profile-action";
import { LOADING_STATUS } from "slices/redux-constants";

const initialState = {
    data: {},
    loading: LOADING_STATUS.IDLE,
    error: false
}

const PendingReducer = (state, action) => {
    console.log(action.payload)
    return {
        ...state,
        error: false,
        loading: LOADING_STATUS.PENDING
    }
}

const FulfillReducer = (state, action) => {
    console.log(action.payload)
    console.log(current(state))
    return {
        ...state,
        error: false,
        loading: LOADING_STATUS.FULFILLED,
        data: action.payload
    }
}

const RejectReducer = (state, action) => {
    return {
        ...state,
        error: true,
        loading: LOADING_STATUS.REJECTED
    }
}

const creditSlice = createSlice({
    name: 'Credit',
    initialState,
    extraReducers: {
        [PostCredit.pending]: PendingReducer,
        [PostCredit.fulfilled]: FulfillReducer,
        [PostCredit.rejected]: RejectReducer,
        [GetCredit.pending]: PendingReducer,
        [GetCredit.fulfilled]: FulfillReducer,
        [GetCredit.rejected]: RejectReducer
    }
})

export const creditSliceReducers = creditSlice.reducer
export const creditSliceActions = {
    PostCredit,
    GetCredit,
    ...creditSlice.actions
}