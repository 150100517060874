import defaultConfig from './envConfig/default';
import devConfig from './envConfig/dev';
import stageConfig from './envConfig/stage';
import productionConfig from './envConfig/prod';
import { REACT_APP_ENV } from '../constants/process-env-constants';

// Need to set in the .env file on the server
var exportConfig = defaultConfig;
console.log(`REACT_APP_ENV=${REACT_APP_ENV}`);
if (REACT_APP_ENV === 'prod') {
    exportConfig = productionConfig;
} else if (REACT_APP_ENV === 'stage') {
    exportConfig = stageConfig;
} else if (REACT_APP_ENV === 'dev') {
    exportConfig = devConfig;
}

export default exportConfig;
