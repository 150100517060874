import axiosClient from "../axios-client";

const getTradeProfile = async ({ company_id }) => {
  console.log("api - company_id", company_id);
  const response = await axiosClient.get("/ifincredit/customerProfile/getTradeProfile",{ params: { company_id } });
  console.log(response);
  return response;
};

const tradeProfileAPI = {
  getTradeProfile,
};

export default tradeProfileAPI;