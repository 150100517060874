const stagingConfig = {
    "PORT": 8080,
    "BASE_URL": "https://ifincredit-stage.ifingate.com",
    "REACT_APP_API_URL": "https://ifincredit-api-stage.ifingate.com",
    "APP_IFINGATE": "https://stage.ifingate.com",
    "APP_IFINPULSE": "https://stage.ifinpulse.com",
    "APP_IFINHEALTH": "https://stage.ifinhealth.com"
};

export default stagingConfig;
