import axiosClient from "api/axios-client";

export const fetchData = async (data) => {
    const company_id = data
    const response = await axiosClient.get('ifincredit/customerOverview/FetchData', { params: { company_id } })
    return response;
}

export const fetchCompany = async (data) => {
    const company_id = data
    const response = await axiosClient.get('ifincredit/customerOverview/CustomerCompany', { params: { company_id } })
    return response;
}