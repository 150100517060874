import { createSlice, current } from '@reduxjs/toolkit';
import { LOADING_STATUS } from 'slices/redux-constants';
import { getCompanyGroup } from './company-group-actions';

// Reducers
const initialState = {
  data: {},
  loading: LOADING_STATUS.IDLE,
};

const pendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const fulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: { ...action.payload },
  };
};

const rejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};


const companyGroupSlices = createSlice({
  name: 'companyGroup',
  initialState,
  extraReducers: {
    [getCompanyGroup.pending]: pendingReducer,
    [getCompanyGroup.fulfilled]: fulfilledReducer,
    [getCompanyGroup.rejected]: rejectedReducer
  },
});

export const companyGroupReducers = companyGroupSlices.reducer;
export const companyGroupActions = {
    getCompanyGroup,
    ...companyGroupSlices.actions,
};