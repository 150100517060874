import { getPendingApproval } from './task-manager-actions'
import { LOADING_STATUS } from 'slices/redux-constants'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
    loading: LOADING_STATUS.IDLE
}

const PendingReducer = (state, action) => {
    return {
        ...state,
        data: {},
        loading: LOADING_STATUS.PENDING,
        error: false
    }
}

const FulfillReducer = (state, action) => {
    return {
        ...state,
        data: action.payload,
        loading: LOADING_STATUS.FULFILLED,
        error: false
    }
}

const RejectReducer = (state, action) => {
    return {
        ...state,
        data: {},
        loading: LOADING_STATUS.REJECTED,
        error: true
    }
}

const taskManagerSlice = createSlice({
    name: 'taskManager',
    initialState,
    extraReducers: {
        [getPendingApproval.pending]: PendingReducer,
        [getPendingApproval.fulfilled]: FulfillReducer,
        [getPendingApproval.rejected]: RejectReducer
    }
})

export const taskManagerReducers = taskManagerSlice.reducer
export const taskManagerActions = {
    getPendingApproval,
    ...taskManagerSlice.actions
}