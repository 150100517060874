import { isEmpty } from 'lodash';

/**
 * !IMPORTANT All process env variables must be defined her to prevent cicular dependency issue
 */

// export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_ENV = window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__['REACT_APP_ENV'] : process.env.REACT_APP_ENV;
if (isEmpty(REACT_APP_ENV)) {
  throw new Error('REACT_APP_ENV is not defined');
}
