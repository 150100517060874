import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from 'slices/redux-constants';
import { getTradeProfile } from './trade-profile-actions';

// Reducers
const initialState = {
  data: {},
  reportData: {},
  loading: LOADING_STATUS.IDLE,
};

const pendingReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.PENDING,
    error: false,
  };
};

const fulfilledReducer = (state, action) => {
  return {
    ...state,
    loading: LOADING_STATUS.FULFILLED,
    error: false,
    data: { ...action.payload.data },
    reportData: { ...action.payload.reportData }
  };
};

const rejectedReducer = (state, action) => {
  return {
    ...state,
    loading: 'rejected',
    error: action.error,
  };
};


const tradeProfileSlices = createSlice({
  name: 'tradeProfile',
  initialState,
  extraReducers: {
    [getTradeProfile.pending]: pendingReducer,
    [getTradeProfile.fulfilled]: fulfilledReducer,
    [getTradeProfile.rejected]: rejectedReducer,
  },
});

export const tradeProfileReducers = tradeProfileSlices.reducer;
export const tradeProfileActions = {
    getTradeProfile,
    ...tradeProfileSlices.actions,
};