import { createAsyncThunk } from '@reduxjs/toolkit';
import userApi from 'api/user-api';

const getUserInfo = createAsyncThunk(`users/getInfoAsync`, async (userId) => {
  const response = await userApi.getUserInfo(userId);
  return response.data;
});

const exportObj = {
  getUserInfo,
}

export default exportObj;
